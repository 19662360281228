import { Box, Dialog, DialogContent, DialogTitle, Divider, List, ListItem, ListItemText, Typography, useTheme } from '@mui/material';
import moment from "moment";
import React from 'react';
import { ContractRole, IContract } from "src/@types/auth";
import useAuth from "src/hooks/useAuth";

interface ContractSelectionDialogProps {
    open: boolean;
    contracts: IContract[];
    onClose: (selectedContract: IContract | null) => void;
}

const ContractSelectionDialog: React.FC<ContractSelectionDialogProps> = ({ open, contracts, onClose }) => {
    const theme = useTheme();
    const { user } = useAuth();
    const firstName = user.name.split(' ')[0];

    const planNames = [
        { name: 'SEMPRE MAIS', sempre: 'Sempre', capitalize: 'Mais' },
        { name: 'SEMPRE MELHOR', sempre: 'Sempre', capitalize: 'Melhor' },
        { name: 'SEMPRE PLENO', sempre: 'Sempre', capitalize: 'Pleno' },
        { name: 'SEMPRE DO SEU JEITO', sempre: 'Sempre ', capitalize: 'do Seu Jeito' },
    ];

    const dialogStyle = { '& .MuiDialog-paper': { backgroundColor: theme.palette.grey[50] } };

    const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
        if (reason !== "backdropClick") {
            onClose(null);
        }
    };

    const roleTransform = (role: ContractRole) => {
        switch (role) {
            case 'USER':
                return 'Titular';
            case 'DEPENDENT':
            case 'PROPOSAL_DEPENDENT':
                return 'Dependente';
            default:
                return '';
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth sx={dialogStyle}>
            <DialogTitle align="center">
                <Box display="flex" flexDirection="row" justifyContent="center">
                    <Typography
                        color={theme.palette.primary.main}
                        fontWeight="bold"
                        fontFamily="Open Sans"
                        fontSize="20px"
                        marginRight="8px"
                    >
                        Bem-vindo(a),
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize="20px"
                        color={theme.palette.secondary.main}
                    >
                        {firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()}!
                    </Typography>
                </Box>
                <Typography variant="subtitle2" color={theme.palette.grey[500]}>Qual contrato você deseja utilizar?</Typography>
            </DialogTitle>
            <DialogContent style={{ marginTop: '15px' }}>
                <List>
                    {contracts.map((contract, index) => {
                        const name = planNames.find((p) => p.name.includes(contract.name));

                        return (
                            <Box key={contracts.indexOf(contract)} onClick={() => onClose(contract)} boxShadow="0 5px 10px rgba(0, 0, 0, 0.1)" borderRadius={2} padding={2} style={{ backgroundColor: 'white', marginBottom: index == contracts.length - 1 ? 0 : 25, cursor: 'pointer' }}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Box display="flex" flexDirection="row">
                                        <Typography
                                            color={theme.palette.primary.main}
                                            fontWeight="bold"
                                            fontFamily="Open Sans"
                                            fontSize="20px"
                                            marginRight="8px"
                                        >
                                            {name?.sempre ?? contract.name}
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize="20px"
                                            color={theme.palette.secondary.main}
                                        >
                                            {name?.capitalize}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Typography
                                            color={
                                                contract.active ? theme.palette.success.main : theme.palette.error.main
                                            }
                                            fontWeight="bold"
                                            fontFamily="Open Sans"
                                            fontSize="12px"
                                            marginRight="8px"
                                        >
                                            {contract.active ? 'Ativo' : 'Desativado'}
                                        </Typography>
                                        <Box
                                            borderRadius={100}
                                            width={10}
                                            height={10}
                                            bgcolor={
                                                contract.active ? theme.palette.success.main : theme.palette.error.main
                                            }
                                        >
                                            {''}
                                        </Box>
                                    </Box>
                                </Box>
                                {contract.role != 'LEAD' && (<Typography variant="subtitle2">{roleTransform(contract.role)} • Contrato nº: {contract.contract_number}</Typography>)}
                                {contract.role == 'LEAD' && (<Typography variant="subtitle2" fontWeight="bold" color={theme.palette.primary.main}>Expira em {moment(contract.access_expire_in).format('DD/MM/YYYY')}</Typography>)}
                                <Typography variant="subtitle2" fontWeight="bold" color={theme.palette.primary.main} marginTop={1}>Selecionar este contrato</Typography>
                            </Box>

                        )
                    })}
                </List>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={() => onClose(null)}>Cancelar</Button>
                <Button onClick={handleConfirm} disabled={!setSelectedContract}>Confirmar</Button>
            </DialogActions> */}
        </Dialog>
    );
};

export default ContractSelectionDialog;