import { Box, Button, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import MyAvatar from '../../../components/MyAvatar';
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
import React from "react";
import Iconify from "src/components/Iconify";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

function formatDate(date: Date | undefined) {
  if (!date) return '-';

  return new Intl.DateTimeFormat('pt-BR').format(new Date(date));
}

export default function NavbarAccount({ isCollapse }: Props) {
  const { user, contracts, selectedContract, setContract } = useAuth();

  const nameSplit = user?.name.split(' ');
  const firstLastName = nameSplit[0] + ' ' + nameSplit[nameSplit.length - 1];
  const firstMidlleName = nameSplit[0] + ' ' + nameSplit[1];

  const handleChangeContract = () => {
    setContract(null);
  }

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
        }),
      }}
    >
      <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.settings}>

        <MyAvatar />
      </Link>

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="subtitle2" width={isCollapse ? '' : '150px'} noWrap>
          {user.name.lenght < 18
            ? user.name
            : firstLastName.length < 18
              ? firstLastName
              : firstMidlleName.length < 18
                ? firstMidlleName
                : nameSplit[0]}
        </Typography>
        {selectedContract?.role !== 'LEAD' && (<React.Fragment>
          <Typography variant="subtitle2" fontSize={'9pt'} noWrap sx={{ color: 'text.secondary' }}>
            {selectedContract?.role === 'USER' || selectedContract?.role === 'PROPOSAL_USER'
              ? 'Titular'
              : 'Dependente'}
          </Typography>
          <Typography variant="subtitle2" fontSize={'9pt'} sx={{ color: 'text.secondary' }}>
            Contrato nº: {selectedContract?.contract_number || '-'}
          </Typography>
        </React.Fragment>)}
        {selectedContract?.role === 'LEAD' && (<Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          Expira em: {formatDate(selectedContract.access_expire_in)}
        </Typography>)}
        {contracts.length > 1 && (<Button
          variant="text"
          startIcon={<Iconify icon={'material-symbols:change-circle'} width={18} />}
          onClick={handleChangeContract}
          sx={{ mt: 1, textAlign: 'left', fontSize: '9pt', padding: '5px 10px' }}
        >
          Mudar contrato
        </Button>)}
      </Box>
    </RootStyle>

  );
}
