import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { IBenefitItem, ICustomBenefitGroup, benefitMapping } from 'src/@types/benefitMapping';
import { benefitsPlans } from 'src/@types/benefitsPlans';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import ViewContractModal from 'src/components/ViewContractModal';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import axiosInstance from 'src/utils/axios';
import { getDependents } from '../ManageDependents';
import IPlan, { IBenefit, IDependent } from './@types';

let listPlans: IPlan[] = [];

export function PlanContrat() {
  const planNames = [
    { name: 'SEMPRE MAIS', sempre: 'Sempre', capitalize: 'Mais' },
    { name: 'SEMPRE MELHOR', sempre: 'Sempre', capitalize: 'Melhor' },
    { name: 'SEMPRE PLENO', sempre: 'Sempre', capitalize: 'Pleno' },
    { name: 'SEMPRE DO SEU JEITO', sempre: 'Sempre ', capitalize: 'do Seu Jeito' },
  ];

  const { themeStretch } = useSettings();
  const { user, selectedContract } = useAuth();
  const isTitular = selectedContract?.role == 'USER' || selectedContract?.role == 'PROPOSAL_USER';
  const theme = useTheme();
  const navigate = useNavigate();
  const [removeLoading, setRemoveLoading] = useState(false);
  const [dependents, setDependents] = useState<IDependent[]>([]);
  const [open, setOpen] = useState(false);

  async function callCrc() {
    try {
      const a = document.createElement('a');
      a.href = 'tel:8440002002';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  async function callCrcWhatsapp() {
    try {
      const a = document.createElement('a');
      a.href = 'https://wa.me/558440002002';
      a.target = '_blank';
      a.click();
    } catch (err) {
      console.log('Não foi possível chamar o crc');
    }
  }

  const getUniqueBenefits = (benefits: IBenefit[]): IBenefit[] => {
    const uniqueBenefitsMap: { [comp: string]: IBenefit } = {};
    benefits.forEach((benefit) => {
      if (!uniqueBenefitsMap[benefit.comp]) {
        uniqueBenefitsMap[benefit.comp] = benefit;
      }
    });
    return Object.values(uniqueBenefitsMap);
  };

  const getContract = async () => {
    listPlans = [];
    let { data } = await axiosInstance.get(`/users/contracts`);
    const activeContracts = data.filter((contract: IPlan) => contract.active && contract.id === selectedContract?.id);

    const contractsWithBenefits = await Promise.all(
      activeContracts.map(async (contract: IPlan) => {
        try {
          const response = await axiosInstance.get<IBenefit[]>(`/users/getProducts/${contract.id}`);
          const BenefitsData = response.data;

          // Filtra os benefícios para remover duplicatas antes de adicioná-los ao contrato.
          const uniqueBenefits = getUniqueBenefits(BenefitsData);

          // Adiciona o benefício "Sempre Único - Aditivo" se hasAdditive for verdadeiro.
          if (contract.hasAdditive) {
            uniqueBenefits.push({
              comp: 'sempre-unico-aditivo',
              desc: 'Benefício aditivo exclusivo',
            });
          }

          return { ...contract, benefits: uniqueBenefits }; // Retorna o contrato com benefícios filtrados
        } catch (error) {
          console.error('Erro ao buscar produtos do contrato', error);
          return contract; // Em caso de erro, apenas retorna o contrato sem produtos
        }
      })
    );

    // Atualizamos a lista de planos com os contratos e seus produtos
    listPlans.push(...contractsWithBenefits);

    const dependentsResult = await getDependents();
    setDependents(dependentsResult);
    setRemoveLoading(true);
  };

  const CardStyle = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    border: '1px solid #919EAB3D',
    maxWidth: '480px',
    borderRadius: '16px',
    padding: '16px',
    margin: '20px',
  }));

  const ContentInfo = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
    borderRadius: '16px',
    padding: '20px',
    mt: '1rem',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  }));
  const renderBenefits = (benefitCompCodes: string[]): React.ReactNode => {
    const groupedBenefits = benefitCompCodes.reduce(
      (acc: { [key: string]: IBenefitItem[] }, compCode) => {
        const defaultBenefit = benefitMapping.defaultProduct[compCode];
        if (defaultBenefit) {
          acc.default = acc.default || [];
          acc.default.push(defaultBenefit);
        } else {
          Object.entries(benefitMapping.customProduct).forEach(([groupKey, group]) => {
            if (group.items.some((item) => item.comp === compCode)) {
              acc[groupKey] = acc[groupKey] || [];
              acc[groupKey].push(...group.items.filter((item) => item.comp === compCode));
            }
          });
        }
        return acc;
      },
      {}
    );

    return Object.entries(groupedBenefits).flatMap(([groupKey, benefits]) => {
      const isCustomGroup = groupKey !== 'default';
      const group: ICustomBenefitGroup | undefined = isCustomGroup
        ? benefitMapping.customProduct[groupKey]
        : undefined;

      if (isCustomGroup && group) {
        return [
          <Box
            key={`group-${groupKey}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mx: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {group.icon && (
                <Iconify
                  icon={group.icon}
                  color={theme.palette.primary.light}
                  width={20}
                  height={20}
                  mr="15px"
                />
              )}
              <Typography variant="body2" fontWeight={700}>
                {group.group}
              </Typography>
            </Box>
            <div
              id={`${groupKey}tooltip-${group.description}`}
              data-tooltip-content={group.description}
            >
              <Iconify
                icon="ri:information-line"
                width="25px"
                height="25px"
                color={theme.palette.primary.main}
              />
            </div>
            <ReactTooltip
              style={{
                backgroundColor: theme.palette.primary.light,
                width: '80%',
                maxWidth: '400px',
                wordBreak: 'break-word',
              }}
              anchorId={`${groupKey}tooltip-${group.description}`}
            />
          </Box>,
          ...benefits.map((benefit) => renderBenefitItem(benefit, true)),
        ];
      } else {
        return benefits.map((benefit) => renderBenefitItem(benefit, false));
      }
    });
  };

  const renderBenefitItem = (benefit: IBenefitItem, isCustomGroup: boolean) => (
    <div
      key={benefit.comp}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: '15px',
        marginLeft: isCustomGroup ? '50px' : '15px',
        marginTop: '10px',
        marginBottom: '10px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {!isCustomGroup && benefit.icon && (
          <Iconify
            icon={benefit.icon}
            color={theme.palette.primary.light}
            width={20}
            height={20}
            marginRight="15px"
          />
        )}
        <Typography variant="body2" fontWeight={isCustomGroup ? 400 : 700} fontSize="14px">
          {benefit.title}
        </Typography>
      </Box>
      <Box>
        {!isCustomGroup && (
          <>
            <div
              id={`${benefit.comp}tooltip-${benefit.description}`}
              data-tooltip-content={benefit.description}
            >
              <Iconify
                icon="ri:information-line"
                width="25px"
                height="25px"
                color={theme.palette.primary.main}
              />
            </div>
            <ReactTooltip
              style={{
                backgroundColor: theme.palette.primary.light,
                width: '80%',
                maxWidth: '400px',
                wordBreak: 'break-word',
              }}
              anchorId={`${benefit.comp}tooltip-${benefit.description}`}
            />
          </>
        )}
      </Box>
    </div>
  );

  useEffect(() => {
    if (!isTitular) {
      navigate(PATH_DASHBOARD.general.app);
    } else {
      getContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Meu Plano Sempre">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <div style={{ display: 'flex' }}>
          <HeaderBreadcrumbs
            arrowClickBack={() => navigate(PATH_DASHBOARD.general.app)}
            heading={`Meu Plano Sempre`}
            links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Meu Plano Sempre' }]}
          />
        </div>
        <Divider sx={{ marginBottom: '20px' }} />
        <ContentInfo>
          {removeLoading ? (
            listPlans.map((plan, index) => {
              const name = planNames.find((p) => p.name.includes(plan.name));

              return (
                <CardStyle key={plan.id} id="contract">
                  {name ? (
                    // Titulo + ativo
                    <Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
                      <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }}>
                        <Typography
                          color={theme.palette.primary.main}
                          fontWeight="bold"
                          fontFamily="Open Sans"
                          fontSize="20px"
                          marginLeft="20px"
                          marginTop="20px"
                          marginRight="8px"
                        >
                          {name.sempre}
                        </Typography>
                        <Typography
                          fontFamily="Open Sans"
                          fontSize="20px"
                          color={theme.palette.secondary.main}
                          marginTop={{ xs: '1px', sm: '20px' }}
                          marginLeft={{ xs: '20px', sm: '0px' }}
                        >
                          {name.capitalize}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        marginRight="20px"
                      >
                        <Typography
                          color={
                            plan.active ? theme.palette.success.main : theme.palette.error.main
                          }
                          fontWeight="bold"
                          fontFamily="Open Sans"
                          fontSize="12px"
                          marginLeft="20px"
                          marginTop="20px"
                          marginRight="8px"
                        >
                          {plan.active ? 'Ativo' : 'Desativado'}
                        </Typography>
                        <Box
                          borderRadius={100}
                          width={10}
                          height={10}
                          marginTop="20px"
                          bgcolor={
                            plan.active ? theme.palette.success.main : theme.palette.error.main
                          }
                        >
                          {''}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Typography
                      variant="h5"
                      color={theme.palette.primary.main}
                      fontSize="20"
                      margin="20px"
                    >
                      {plan.name}
                    </Typography>
                  )}
                  {!plan.name.includes('JAZIGO') && (
                    <>
                      <Link to={'/dashboard/card'} style={{ textDecoration: 'none' }}>
                        <Typography
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          color={theme.palette.grey[800]}
                          fontSize="12px"
                          marginLeft="20px"
                          marginTop="10px"
                        >
                          <img
                            src="/assets/icons/account_balance_wallet.png"
                            alt="account_balance_wallet"
                            style={{ marginRight: 7 }}
                          />
                          Minha carteirinha
                        </Typography>
                      </Link>
                      {(!plan.name.includes('JAZIGO') && isTitular) && (
                        <Link to={"/dashboard/user/dependents"} style={{ textDecoration: 'none' }}>
                          <Typography
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            color={theme.palette.grey[800]}
                            fontSize="12px"
                            marginLeft="20px"
                            marginTop="20px"
                          >
                            <img
                              src="/assets/icons/mdi_account-group-outline.png"
                              alt="account_balance_wallet"
                              style={{ marginRight: 7 }}
                            />
                            Meus dependentes
                          </Typography>
                        </Link>
                      )}
                    </>
                  )}
                  <Divider style={{ marginLeft: 20, marginRight: 20 }} />
                  {/* Valor */}
                  <Box display="flex" flexDirection="row" justifyContent="space-between">
                    <Typography
                      fontWeight="bold"
                      fontFamily="Open Sans"
                      color={theme.palette.primary.main}
                      fontSize="14px"
                      marginTop="20px"
                      marginLeft="20px"
                    >
                      Valor do meu plano:
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      marginRight="20px"
                      marginTop="20px"
                    >
                      <Typography
                        color={theme.palette.primary.main}
                        fontWeight="bold"
                        fontSize="10px"
                        lineHeight="18px"
                      >
                        R$
                      </Typography>
                      <Typography
                        fontWeight="bold"
                        fontFamily="Open Sans"
                        color={theme.palette.primary.main}
                        fontSize="25px"
                        lineHeight="18px"
                        marginLeft={1}
                      >
                        {new Intl.NumberFormat('pt-BR', {
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                        }).format(Number(plan.monthly_payment))}
                      </Typography>
                    </Box>
                  </Box>
                  {/* --- */}
                  {plan.name.includes('JAZIGO') && (
                    <>
                      <div style={{ display: 'flex' }}>
                        <Typography
                          variant="body2"
                          fontWeight="600"
                          color={theme.palette.grey[600]}
                          fontSize="12px"
                          marginLeft="20px"
                          marginTop="15px"
                        >
                          Cemitério: {plan.unit}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Typography
                          variant="body2"
                          fontWeight="600"
                          color={theme.palette.grey[600]}
                          fontSize="12px"
                          marginLeft="20px"
                        >
                          Setor: {plan.setor} - Quadra: {plan.quadra} - Lote: {plan.lote}
                        </Typography>
                      </div>
                    </>
                  )}
                  <div style={{ display: 'flex' }}>
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color={theme.palette.grey[600]}
                      fontSize="12px"
                      marginLeft="20px"
                    >
                      Nº do contrato: {plan.contract_number}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Typography
                      variant="body2"
                      fontWeight="600"
                      color={theme.palette.grey[600]}
                      fontSize="12px"
                      marginLeft="20px"
                      marginBottom={dependents.length === 0 ? '20px' : '0px'}
                    >
                      Data do contrato: {moment(plan.date_issued).format('L')}
                    </Typography>
                  </div>
                  {dependents.length > 0 && (
                    <div style={{ display: 'flex' }}>
                      <Typography
                        variant="body2"
                        fontWeight="600"
                        color={theme.palette.grey[600]}
                        fontSize="12px"
                        marginLeft="20px"
                        marginBottom="10px"
                      >
                        Total de dependentes: {dependents.length}
                      </Typography>
                    </div>
                  )}

                  {plan.contract_file_url && (
                    <>
                      <Divider style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }} />
                      <Box style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            textAlign: 'start',
                          }}
                          onClick={() => setOpen(!open)}
                          endIcon={<IoIosSearch />}
                          style={{ height: 48 }}
                        >
                          <Box width="100%">Visualizar contrato</Box>
                        </Button>
                      </Box>
                      <ViewContractModal plan={plan} open={open} setOpen={setOpen} />
                    </>
                  )}
                  {!plan.name.includes('JAZIGO') && (
                    <>
                      <Box style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<FaCaretDown style={{ marginRight: 5 }} color="white" />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            style={{ backgroundColor: '#00a1b1', borderRadius: 10 }}
                          >
                            <Typography color={'white'} fontWeight="700" fontSize="14px">
                              Ver todos os benefícios do plano
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box style={{ marginLeft: 20, marginRight: 20 }}>
                              <Typography
                                color={theme.palette.grey[600]}
                                textTransform="uppercase"
                                fontWeight="700"
                                fontSize="12px"
                                lineHeight="18px"
                                letterSpacing="1.2px"
                                marginBottom="10px"
                                marginTop="10px"
                              >
                                benefícios do plano
                              </Typography>

                              {plan.name === 'SEMPRE DO SEU JEITO'
                                ? plan.benefits &&
                                renderBenefits(plan.benefits.map((benefit) => benefit.comp))
                                : (() => {
                                  const benefitsToRender = [...benefitsPlans];
                                  if (plan.hasAdditive) {
                                    benefitsToRender.push({
                                      title: 'Sempre Único - Aditivo',
                                      icon: 'mdi:star',
                                      description: 'Benefício aditivo exclusivo',
                                    });
                                  }
                                  return benefitsToRender.map((item, i) => (
                                    <div
                                      key={item.title}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        margin: '15px',
                                      }}
                                    >
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Iconify
                                          icon={item.icon || 'mdi:alert-circle-outline'} // Fornece um ícone padrão caso item.icon esteja indefinido
                                          color={theme.palette.primary.light}
                                          width={20}
                                          height={20}
                                          marginRight="15px"
                                        />
                                        <Typography
                                          variant="body2"
                                          fontWeight="bold"
                                          fontSize="14px"
                                        >
                                          {item.title}
                                        </Typography>
                                      </div>
                                      <div
                                        id={`${i}tooltip-${item.title}`}
                                        data-tooltip-content={item.description}
                                      >
                                        <Iconify
                                          icon="ri:information-line"
                                          width="25px"
                                          height="25px"
                                          color={theme.palette.primary.main}
                                        />
                                      </div>
                                      <ReactTooltip
                                        style={{
                                          backgroundColor: theme.palette.primary.light,
                                          width: '80%',
                                          maxWidth: '400px',
                                          wordBreak: 'break-word',
                                        }}
                                        anchorId={`${i}tooltip-${item.title}`}
                                      />
                                    </div>
                                  ));
                                })()}

                              <Typography
                                variant="body2"
                                fontWeight={400}
                                color={theme.palette.primary.main}
                                fontSize="14px"
                                pt={3}
                                px={3}
                                textAlign="center"
                              >
                                O asterisco (*) significa que existe um diferencial que varia de
                                acordo com o plano escolhido. ** exceto para planos na unidade de
                                João Pessoa.
                              </Typography>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                      {/* <Box style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            background: theme.palette.secondary.main,
                            textAlign: 'start',
                          }}
                          onClick={() => ''}
                          endIcon={'<FaCaretUp /> : <FaCaretDown />'}
                        >
                          <Box width="100%">Ver todos os benefícios do plano</Box>
                        </Button>
                      </Box> */}
                    </>
                  )}
                </CardStyle>
              );
            })
          ) : (
            <LoadingScreen />
          )}
        </ContentInfo>
        <Card
          sx={{
            width: '100%',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src="/assets/illustrations/headset.png" alt="entre em contato" />
            <Box sx={{ paddingLeft: '1.5rem' }}>
              <Typography
                color={theme.palette.secondary.main}
                fontSize={'18px'}
                fontWeight={'bold'}
              >
                Para maiores informações sobre os seus benefícios e produtos, consulte a nossa
                Central de Relacionamento
              </Typography>
            </Box>
          </Box>

          <Button
            fullWidth
            variant="contained"
            sx={{ background: theme.palette.secondary.main, marginTop: '1rem', maxWidth: '400px' }}
            onClick={callCrcWhatsapp}
          >
            Fale conosco pelo whatsapp
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{ marginTop: '1rem', maxWidth: '400px' }}
            onClick={callCrc}
          >
            Fale conosco pelo telefone
          </Button>
        </Card>
      </Container>
    </Page>
  );
}
